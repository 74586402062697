.animate {
    & :local {
        animation: move 2s linear infinite;
    }
}

@keyframes move {
  from {
      transform: rotate(0deg);
  } to {
      transform: rotate(360deg);
  }
}