// Redesigned Borders, Border Radius, Box Shadow, and Dash Lines values
//arrangements
@default-above: 2;
//	Above Content
@above-all-content: 10;
@above-all-content-top-layer: 12;
//	View Chrome
@mask: 20;
@mask-above: 30;
// Modal
@mask-modal: 100;

// dash lines
@dash-normal: 1 3;
@dash-long: 1 5;

.default-dashline {
  fill: none;
  stroke: var(--primary-text);
}

.dashline-normal {
  .default-dashline;

  stroke-dasharray: @dash-normal;
}

.dashline-long {
  .default-dashline;

  stroke-dasharray: @dash-long;
}

.limit-lines(@amount) {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: @amount;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.TwoTonePage {
  display: flex;
  flex-flow: row nowrap;

  > section {
    display: flex;
    flex: 1 100%;
    box-sizing: border-box;
    padding-top: @size-40;
    padding-bottom: @size-48;
    width: 100%;

    &:first-of-type {
      background: var(--secondary-bg);
      flex: 1;
      max-width: @size-912;
      padding-left: var(--theme-gutter);
      padding-right: @size-44;
    }

    &:last-of-type {
      // background: rgba(var(--primary-bg-rgb), 0.5);
      background: var(--quaternary-bg);
      flex: 1;
      max-width: @size-528;
      padding-left: @size-44;
      padding-right: var(--theme-gutter);
    }
  }

  @media @breakpoint-mobile-tiny {
    > section {
      &:first-of-type {
        padding-left: @size-44;
        padding-right: @size-44;
      }

      &:last-of-type {
        max-width: @size-340;
        width: @size-340;
      }
    }
  }
}

.TwoTonePageGrid {
  display: grid;
  grid-template-columns: 1fr minmax(@size-434, @size-528);

  > section {
    &:first-of-type,
    &:last-of-type {
      max-width: 100%;
      width: 100%;
    }

    @media @breakpoint-mobile-tablet {
      &:first-of-type {
        overflow: hidden;
      }
    }
  }
}

.TableTitle {
  .text-20-bold;

  color: var(--secondary-text);
  text-transform: capitalize;
}

.ThemeBorder {
  border: @size-1 solid var(--theme-border);
  border-radius: @size-8;
}

.HoverDropShadow {
  box-shadow: unset;
  transition: box-shadow 0.3s ease-out;

  &:hover:not(:disabled) {
    box-shadow: var(--theme-box-shadow);
    transition: box-shadow 0.3s ease-out;
  }
}

.FixedBoxShadow {
  box-shadow: var(--theme-box-shadow);
}

.PrimaryTopBorderRadius {
  border-top-left-radius: var(--theme-primary-radius);
  border-top-right-radius: var(--theme-primary-radius);
}

.PrimaryBottomBorderRadius {
  border-bottom-left-radius: var(--theme-primary-radius);
  border-bottom-right-radius: var(--theme-primary-radius);
}

.PrimaryBorderRadius {
  .PrimaryBottomBorderRadius;
  .PrimaryTopBorderRadius;
}

.SecondaryTopBorderRadius {
  border-top-left-radius: var(--theme-secondary-radius);
  border-top-right-radius: var(--theme-secondary-radius);
}

.SecondaryBottomBorderRadius {
  border-bottom-left-radius: var(--theme-secondary-radius);
  border-bottom-right-radius: var(--theme-secondary-radius);
}

.SecondaryBorderRadius {
  .SecondaryBottomBorderRadius;
  .SecondaryTopBorderRadius;
}

.TertiaryTopBorderRadius {
  border-top-left-radius: var(--theme-tertiary-radius);
  border-top-right-radius: var(--theme-tertiary-radius);
}

.TertiaryBottomBorderRadius {
  border-bottom-left-radius: var(--theme-tertiary-radius);
  border-bottom-right-radius: var(--theme-tertiary-radius);
}

.TertiaryBorderRadius {
  .TertiaryBottomBorderRadius;
  .TertiaryTopBorderRadius;
}
