@import (reference) "~assets/styles/shared";

.SimplifiedActionButton {
  border: @size-1 solid var(--theme-border);
  background: var(--primary-text);
  color: var(--inverted-primary-text);
  min-height: @size-68;
  height: @size-68;
  max-height: @size-68;

  > span:first-of-type {
    .text-20-bold;
  }

  &:not(:disabled) {
    &:hover {
      background: rgba(var(--primary-text-rgb), 0.85);
      color: var(--inverted-primary-text);
    }

    &:focus-within:hover:active {
      background: var(--inverted-primary-bg);
      color: var(--inverted-primary-text);
    }
  }

  &:not(:disabled):not(.Disabled):focus,
  &:not(:disabled):not(.Disabled):focus-within {
    background-color: var(--primary-text);
    color: var(--inverted-primary-text);
    outline: 0;
    border: @size-1 solid var(--theme-border);
    box-shadow: none;
  }

  &:not(:disabled):not(.Disabled):active {
    background-color: var(--tertiary-bg);
    color: var(--primary-text);
  }
}

.ApproveButton {
  .SimplifiedActionButton;
}

.BuySellButton {
  .SimplifiedActionButton;

  flex-flow: column nowrap;
  padding: 0 @size-16;

  > span:first-of-type {
    .text-20-bold;
  }

  > span:nth-of-type(2) {
    .text-12-semi-bold;
  }

  &:disabled {
    pointer-events: none;
    background: var(--secondary-bg);
    border: @size-1 solid var(--theme-border);
    color: var(--secondary-text);
    // purposeful override for specifically just this button for readability.
    opacity: 1;
  }

  &.Error {
    pointer-events: none;
    color: var(--failed);
    background: rgba(var(--failed-rgb), 0.15);
    opacity: 1;
    border: 0;
  }
}

.ReviewTransactionButton {
  .SimplifiedActionButton;

  border: @size-1 solid var(--success);
  background: var(--success);
  color: var(--inverted-primary-text);

  &:not(:disabled) {
    &:hover {
      background: rgba(var(--success-rgb), 0.85);
      color: var(--inverted-primary-text);
    }

    &:focus-within:hover:active {
      background: var(--success);
      color: var(--inverted-primary-text);
    }
  }

  &:not(:disabled):not(.Disabled):focus,
  &:not(:disabled):not(.Disabled):focus-within {
    background-color: var(--success);
    color: var(--inverted-primary-text);
    outline: 0;
    border: @size-1 solid var(--success);
    box-shadow: none;
  }

  &:not(:disabled):not(.Disabled):active {
    background-color: var(--success);
    color: var(--inverted-primary-text);
  }
}

.TinyTransparentButton {
  background-color: transparent;
  border: 0;

  &:not(:disabled):not(.Disabled) {
    &:hover {
      background-color: var(--tertiary-bg);
      border: 0;
    }

    &:focus {
      background-color: transparent;
    }

    &:active,
    &.Selected {
      background-color: var(--tertiary-bg);
    }
  }
}
