@import (reference) "~assets/styles/shared";
@import (reference) "~modules/common/buttons.styles.less";

.TopNav {
  align-items: center;
  background: transparent;
  border-bottom: @size-1 solid var(--theme-border);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: var(--theme-top-bar-height);
  min-height: var(--theme-top-bar-height);
  max-height: var(--theme-top-bar-height);
  width: 100%;
  position: fixed;
  z-index: @above-all-content;

  >section {
    align-items: center;
    background: var(--secondary-bg);
    box-sizing: border-box;
    display: inline-grid;
    grid-template-columns: auto auto;
    flex: 1;
    grid-gap: @size-16;
    width: 100%;
    height: 100%;

    &:first-of-type {
      max-width: 63rem;
      padding-left: @size-88;
      grid-template-columns: 0fr 1fr;
      grid-gap: @size-80;

      @media @breakpoint-mobile-tablet {
        grid-template-columns: auto;
        grid-gap: 0;
      }

      >ol {
        align-items: center;
        list-style: none;
        display: flex;
        flex-flow: row nowrap;
        margin: 0;
        padding: 0;
        min-height: @size-32;
        height: @size-32;

        >li {
          flex: 0 auto;
          margin-right: @size-40;

          &:last-of-type {
            margin-right: 0;
          }

          >a {
            .text-20-bold;

            text-decoration: none;
            color: #FF995A;

            &:hover {
              color: #f87b2d;
            }

            &:active {
              color: #f87b2d;
            }
          }

          &:hover {
            >a {
              color: #f87b2d;

              &[disabled] {
                color: #f8b387;
              }
            }
          }

          &.Active {
            >a {
              color: #f87b2d;
            }
          }
        }
      }
    }

    &:last-of-type {
      max-width: @size-528;
      padding-right: @size-88;
      grid-gap: @size-8;
      align-items: center;
      justify-content: flex-end;

      >button:first-of-type:first-of-type {
        margin-left: auto;
      }
    }
  }

  &.TwoTone>section:last-of-type {
    background: var(--quaternary-bg);
  }
}

.ThemeSelection {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: @size-4;

  &.ThemeSelection {
    grid-template-columns: auto auto auto;
  }

  >button {
    .text-12-semi-bold;
    .ThemeBorder;

    background: transparent;
    color: var(--secondary-text);

    &.Active {
      border-color: var(--primary-text);
      color: var(--primary-text);
    }
  }
}

:root[theme="DARK"] {
  .TopNav>section:first-of-type>a>section>svg>g>path:not(:first-of-type) {
    fill: var(--primary-text);
  }
}

@media @breakpoint-tiny {
  .TopNav {
    &.OnMarketsView {
      &.TwoTone>section:last-of-type {
        background: var(--secondary-bg);
      }
    }

    &.TwoTone>section:first-of-type {
      padding-left: @size-44;
    }

    &.TwoTone>section:last-of-type {
      max-width: @size-340;
      width: @size-340;
      padding-right: @size-44;
    }
  }
}

@media @breakpoint-mobile-tablet {
  .TopNav {
    background: var(--secondary-bg);
    display: grid;
    grid-template-columns: auto 1fr;

    >section:first-of-type {
      padding-left: @size-16;
      grid-column: 1;

      >a {
        width: @size-56;
        height: @size-40;

        >section {
          width: @size-56;
          height: @size-40;
          align-items: center;
          display: flex;
          align-content: center;
        }
      }
    }

    >section:last-of-type {
      padding-right: @size-16;
      grid-column: 2;
      margin-left: auto;
    }

    &.TwoTone>section:last-of-type {
      background: transparent;
    }
  }
}

.SettingsButton {
  .ThemeBorder;
  .text-16-bold;

  background: var(--secondary-bg);
  min-height: @size-48;
  max-height: @size-48;
  height: @size-48;
  padding: 0 @size-14;
  color: var(--primary-text);
  outline: none;
  grid-gap: @size-8;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active,
  &:hover:active {
    background: var(--tertiary-bg);
    border: @size-1 solid var(--theme-border);
  }

  &:focus-within {
    background: var(--secondary-bg);
    outline: auto;
    outline-color: var(--theme-focus-color);
  }
}

.SettingsMenuWrapper {
  position: relative;

  >button>svg {
    margin: 0;
  }
}

.SettingsMenu {
  .ThemeBorder;
  .FixedBoxShadow;
  .TertiaryBorderRadius;

  background: var(--primary-bg);
  position: absolute;
  top: @size-60;
  right: 0;
  display: grid;
  padding: @size-24;
  grid-gap: @size-24;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  max-width: 261px;
  min-width: 261px;

  @media @breakpoint-mobile-tablet {
    top: auto;
    bottom: 61px;
    right: 0;
  }

  >li {
    >h2 {
      .text-16-bold;

      color: var(--primary-text);
      margin: 0;
    }

    >label {
      .text-14;

      color: var(--primary-text);
      margin-bottom: @size-12;
      display: flex;
      align-items: center;
      line-height: @size-22;

      >span {
        margin-left: @size-5;
      }
    }

    >ul {
      display: flex;
      flex-flow: row nowrap;
      grid-gap: @size-8;
      align-self: flex-end;

      >li>div {
        .ThemeBorder;

        background-color: transparent;
        box-sizing: border-box;
        display: flex;
        flex-flow: row nowrap;
        padding: @size-5 @size-8;
        max-height: @size-25;
        min-height: @size-25;
        align-items: center;
        justify-content: center;

        >input[type="number"] {
          -moz-appearance: textfield;
          .text-12-semi-bold;

          background: transparent;
          color: var(--secondary-text);
          padding: 0;
          text-align: right;
          outline-style: none;
          border: none;
          min-width: @size-45;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          &:focus {
            color: var(--primary-text);
            outline-style: none;
            border: none;
          }
        }

        >span {
          .text-12-semi-bold;

          color: var(--primary-text);
          margin-left: @size-4;
        }

        &[class*="Selected"] {
          background-color: var(--tertiary-bg);
          border: @size-1 solid var(--secondary-text);

          >input {
            color: var(--primary-text);
          }

          &:not(:disabled):not(.Disabled) {
            &:hover {
              background-color: var(--tertiary-bg);
              border: @size-1 solid var(--secondary-text);
            }

            &:focus {
              background-color: var(--tertiary-bg);
            }

            &:active {
              background-color: var(--tertiary-bg);
            }
          }
        }
      }
    }
  }
}

.Selected {
  background: var(--tertiary-bg);
}

.MobileMenuButton {
  .ThemeBorder;

  max-width: @size-56;
  min-width: @size-56;
  width: @size-56;
  max-height: @size-48;
  min-height: @size-48;
  height: @size-48;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: none;
  background: transparent;

  >svg {
    width: @size-20;
    height: @size-16;

    >path {
      fill: var(--primary-text);
    }
  }
}

BUTTON.Secondary.predicnSettingColor {
  >svg {

    >path[fill],
    >g>path[fill] {
      fill: #FF995A !important;
    }

    >line,
    >path[stroke],
    >g>path[stroke] {
      stroke: #FF995A !important;
    }
  }
}