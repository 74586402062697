@import (reference) "../../assets/styles/shared.less";

@keyframes greenNPurple {
  from {
    box-shadow: 0 @size-8 @size-40 rgba(var(--color-brand-tertiary-rgb), 0.15);
  }
  to {
    box-shadow:  0 @size-8 @size-40 rgba(var(--color-brand-rgb), 0.15);
  }
}


.MarketCard {
  .HoverDropShadow;

  border-radius: @size-8;

  &.Popular {
    background: linear-gradient(to left, var(--color-brand), var(--color-brand-tertiary));
    padding: @size-2;
    box-shadow: unset;
    transition: box-shadow 0.3s ease-out;

    &:hover:not(:disabled) {
      box-shadow:  0 @size-8 @size-40 rgba(var(--color-brand-rgb), 0.15);
      transition: box-shadow 0.3s ease-out;
      animation: greenNPurple 1.25s linear infinite alternate; 
    }

    > a,
    > div,
    > section > div {
      border: none;
      padding: @size-22;
    }
  }

  > a,
  > div,
  > section > div {
    .text-14-bold;
    .ThemeBorder;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: var(--primary-bg);
    display: grid;
    height: 100%;
    grid-template-columns: @size-46 @size-60 1fr;
    padding: @size-24;
    grid-template-rows: auto;

    > article {
      display: grid;
      grid-template-columns: @size-32 @size-60 1fr;
      grid-template-rows: @size-32;
      grid-column: span 3;
      grid-row: 1;
      grid-gap: @size-16 @size-8;

      &.Trading {
        grid-gap: 0 @size-8;

        > div:last-of-type {
          > button {
            display: flex;
          }

          > div[class*="ValueLabel"] {
            display: grid;
          }
        }
      }

      > div[class*="InSettlement"],
      > div[class*="Resolved"] {
        display: none;
        grid-row: 1;
        grid-column: 1 / span 3;
      }

      > div[class*="CategoryIcon"] {
        display: flex;
        margin-right: @size-8;
        grid-column: 1;
        grid-row: 1;
      }

      > div[class*="CategoryLabel"] {
        grid-column: 2 / span 2;
        grid-row: 1;
        width: fit-content;
      }

      > div:last-of-type {
        align-items: center;
        justify-content: flex-end;
        display: flex;
        grid-row: 1;
        grid-column: 3;

        > span:not(:last-of-type) {
          margin-right: @size-8;
        }

        > button {
          display: none;
        }

        > div[class*="ValueLabel"] {
          display: none;

          > span:last-of-type {
            .text-12-semi-bold;

            margin-top: @size-2;
            justify-content: flex-end;
          }
        }
      }
    }

    > section > span,
    > a > span,
    > span {
      > span:first-of-type {
        .text-14-semi-bold;

        color: var(--primary-text);
        line-height: 140%;

        > span:first-of-type {
          .limit-lines(1);
        }

        > span:last-of-type {
          .limit-lines(2);
        }

        > span:first-of-type:last-of-type {
          .limit-lines(3);
        }
      }

      > span:last-of-type {
        .text-12;

        color: var(--secondary-text);
        white-space: nowrap;
        word-break: none;
        display: inline-block;
        text-transform: uppercase;

        @media @breakpoint-mobile-tablet {
          .text-10;
        }
      }

      &:first-of-type {
        display: grid;
        grid-gap: @size-6;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        margin: @size-16 0 @size-4;
        text-align: left;
        height: @size-78;
      }
    }

    > span {
      grid-column: 1 / span 3;
    }

    > a,
    > section {
      grid-column: 1 / span 3;
      width: 100%;
      min-height: @size-180;
      display: grid;
      grid-template-columns: @size-80 @size-9 1fr;
      grid-template-rows: auto;
      margin-bottom: 0;
      grid-gap: @size-8;

      > span {
        grid-column: 1 / span 3;
        grid-row: 1;
      }

      > div:first-of-type {
        // volume labels (Total Volume)
        grid-column: 1 / span 2;
        grid-row: 2;
        align-items: flex-end;
        margin-top: @size-16;

        > span:last-of-type {
          .text-14-semi-bold;

          margin-top: @size-2;
        }
      }

      > div:nth-of-type(2) {
        // volume labels (Liquidity)
        // align-items: flex-end;
        grid-column: 1 / span 2;
        grid-row: 3;
        display: flex;
        flex-flow: column nowrap;
        height: 100%;

        > span {
          &:first-of-type {
            .text-12;

            color: var(--secondary-text);
          }

          &:last-of-type {
            .text-14-semi-bold;

            margin-top: @size-2;
            color: var(--primary-text);
          }
        }
      }

      > div.OutcomesTable {
        grid-column: 3;
        grid-row: 2 / span 2;
        margin-top: auto;
      }

      > span.ExtraOutcomes {
        .text-10-semi-bold;

        color: var(--secondary-text);
        position: relative;
        top: 210%;
        height: 0;
        overflow: visible;
        text-transform: uppercase;
        margin: 0;
        text-align: right;
      }
    }
  }

  &.NoLiquidity {
    > a,
    > div,
    > section > div {
      > div:first-of-type {
        grid-column: 1 / span 3;
        margin-right: 0;
        display: flex;
        flex-direction: column;

        > span {
          .text-14-semi-bold;

          color: var(--secondary-text);
          margin-bottom: @size-16;
        }
      }
    }
  }
}

button.NoLiquidityPill:disabled {
  .text-12;

  align-self: baseline;
  border: 0;
  color: var(--secondary-text);
  cursor: initial;
  max-height: @size-24;
  min-height: @size-24;
  height: @size-24;
  padding: 0;
  opacity: 1;
  border-color: var(--theme-border);
  text-transform: none;
}

span.ExtraOutcomes {
  .text-10-semi-bold;

  color: var(--secondary-text);
  position: relative;
  top: 210%;
  height: 0;
  overflow: visible;
  text-transform: uppercase;
  margin: 0;
  text-align: right;
}

.OutcomesTable {
  border-radius: @size-8;
  display: flex;
  flex-direction: column;

  > div {
    .ThemeBorder;

    border-radius: unset;
    border-top: 0;
    min-height: @size-32;
    height: @size-32;
    max-height: @size-32;
    padding: 0 @size-16;
    display: flex;

    &:first-of-type {
      .ThemeBorder;

      &:not(:last-of-type) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-of-type {
      .ThemeBorder;

      &:not(:first-of-type) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    > span {
      display: flex;
      align-items: center;

      &:first-of-type {
        .text-12;

        flex: 1;
        color: var(--primary-text);
        text-transform: capitalize;
      }

      &:last-of-type {
        .text-14-bold;

        color: var(--primary-text);
      }
    }
    &:not(:first-of-type):not(:last-of-type) {
      border-top: 0;
    }

    &:last-of-type:not(:first-of-type):not(.WinningOutcome) {
      border-top: 0;
    }
  }

  &.hasWinner {
    > div.WinningOutcome {
      border: 0;
      border-radius: 0;
      align-items: center;
      flex: 1 100%;
      min-height: @size-40;
      white-space: nowrap;
      display: grid;
      grid-gap: @size-4 @size-10;
      grid-template-columns: 0fr 0fr;
      grid-template-rows: auto auto;
      margin-top: auto;
      justify-items: flex-end;
      align-self: flex-end;
      padding: 0;

      > span:first-of-type {
        .text-12;

        color: var(--secondary-text);
        text-transform: capitalize;
        line-height: 140%;
        min-height: @size-14;
        max-height: @size-14;
        grid-column: 1;
        grid-row: 1;
      }

      > span:last-of-type {
        .text-16-semi-bold;

        color: var(--primary-text);
        line-height: 140%;
        min-height: @size-22;
        max-height: @size-22;
        grid-column: 1;
        grid-row: 2;
      }

      > svg {
        grid-column: 2;
        grid-row: 2;
      }
    }
  }
}

.LoadingMarketCard {
  .ThemeBorder;

  background-color: var(--primary-bg);
  padding: @size-24;
  max-height: @size-280;

  > div {
    display: grid;

    > div {
      .ShimmerEmptyBlock;
    }

    &:nth-of-type(1) {
      grid-template-columns: @size-40 75px auto;
      display: grid;
      grid-template-rows: @size-32;
      grid-gap: @size-8;
      margin-bottom: @size-20;

      > div:last-of-type {
        width: @size-20;
        height: @size-20;
        margin-left: auto;
        border-radius: @size-100;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    &:nth-of-type(2) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, @size-16);
      grid-gap: @size-4;
      margin-bottom: @size-45;

      > div:last-of-type {
        max-width: 161px;
      }
    }

    &:nth-of-type(3) {
      grid-template-columns: 1fr minmax(@size-200, @size-250);
      grid-template-rows: @size-46 repeat(2, @size-16);
      display: grid;
      grid-column-gap: @size-32;
      grid-row-gap: @size-4;

      > div:last-of-type {
        grid-column: 2;
        grid-row: 1 / span 3;
      }

      > div:nth-of-type(2) {
        grid-column: 1;
        grid-row: 3;
      }

      > div:first-of-type {
        grid-row: 2;
        width: @size-40;
      }
    }
  }
}

@media @breakpoint-mobile-tablet {
  .MarketCard {
    max-width: 100%;

    > a,
    > div {
      max-width: 100%;

      > article {
        &.Trading {
          grid-template-rows: auto;
        }

        &:not(.Trading) {
          > div[class*="InSettlement"],
          > div[class*="Resolved"] {
            display: flex;
            grid-row: 1;
            grid-column: 1 / span 3;
            max-width: fit-content;
            margin-bottom: @size-16;
          }

          > div[class*="CategoryIcon"],
          > div[class*="CategoryLabel"],
          > div:last-of-type {
            grid-row: 2;

            > div[class*="InSettlement"],
            > div[class*="Resolved"] {
              display: none;
            }
          }
        }
      }

      > span {
        margin-bottom: @size-16;
        height: unset;
      }

      > a,
      > section {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        grid-gap: 0;

        > span:first-of-type {
          flex: 1 100%;
          order: 0;
        }

        > span:last-of-type:not(:first-of-type) {
          flex: 1 100%;
          order: 2;
          height: @size-14;
          margin-bottom: @size-4;
        }

        > div:first-of-type {
          align-items: center;
          justify-content: center;
          flex: 0;
          order: 3;
          display: flex;
          flex-flow: row nowrap;
          grid-gap: 0;
          margin-top: @size-8;

          > span:last-of-type {
            .text-12-semi-bold;

            margin-left: @size-4;
            margin-top: 0;
          }
        }

        > div:nth-of-type(2) {
          align-items: center;
          justify-content: center;
          flex: 0;
          order: 4;
          margin-top: @size-8;
          flex-flow: row nowrap;

          > span:last-of-type {
            .text-12-semi-bold;

            margin-left: @size-4;
            margin-top: 0;
          }
        }

        > div.OutcomesTable {
          flex: 1 100%;
          order: 1;
          margin-bottom: @size-8;
        }

        > div[class*="ValueLabel"] > span:last-of-type {
          white-space: nowrap;
        }
      }
    }
  }

  .OutcomesTable {
    grid-row: 3;
    grid-column: 1 / span 3;
  }
}

@media @breakpoint-mobile-tablet {
  .MarketCard > a > a,
  .MarketCard > a > section,
  .MarketCard > div > a,
  .MarketCard > div > a > span {
    height: unset;
  }
}
