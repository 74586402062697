@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Inter-Italic.woff2') format('woff2'),
    url('../fonts/Inter-Italic.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Inter-Medium.woff2') format('woff2'),
    url('../fonts/Inter-Medium.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Inter-MediumItalic.woff2') format('woff2'),
    url('../fonts/Inter-MediumItalic.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../fonts/Inter-SemiBold.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Inter-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Inter-SemiBoldItalic.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Inter-Bold.woff2') format('woff2'),
    url('../fonts/Inter-Bold.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Inter-BoldItalic.woff2') format('woff2'),
    url('../fonts/Inter-BoldItalic.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/Inter-ExtraBold.woff2') format('woff2'),
    url('../fonts/Inter-ExtraBold.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/Inter-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/Inter-ExtraBoldItalic.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/Inter-Black.woff2') format('woff2'),
    url('../fonts/Inter-Black.woff') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/Inter-BlackItalic.woff2') format('woff2'),
    url('../fonts/Inter-BlackItalic.woff') format('woff2');
}

// font-families
@Inter: 'Inter', sans-serif;

// sizes
@size-1: 0.0625rem; // 1px
@size-2: 0.125rem; // 2px
@size-3: 0.1875rem; // 3px
@size-4: 0.25rem; // 4px
@size-5: 0.3125rem; // 5px
@size-6: 0.375rem; // 6px
@size-7: 0.4375rem; // 7px
@size-8: 0.5rem; // 8px
@size-9: 0.5625rem; // 9px
@size-10: 0.625rem; // 10px
@size-11: 0.6875rem; // 11px
@size-12: 0.75rem; // 12px
@size-13: 0.8125rem; // 13px
@size-14: 0.875rem; // 14px
@size-15: 0.9375rem; // 15px
@size-16: 1rem; // 16px
@size-17: 1.0625rem; // 17px
@size-18: 1.125rem; // 18px
@size-19: 1.1875rem; // 19px
@size-20: 1.25rem; // 20px
@size-21: 1.3125rem; // 21px
@size-22: 1.375rem; // 22px
@size-23: 1.4375rem; // 22px
@size-24: 1.5rem; // 24px
@size-25: 1.5625rem; // 25px
@size-26: 1.625rem; // 26px
@size-27: 1.6875rem; // 27px
@size-28: 1.75rem; // 28px
@size-29: 1.8125rem; // 29px
@size-30: 1.875rem; // 30px
@size-31: 1.9375rem; // 31px
@size-32: 2rem; // 32px
@size-34: 2.125rem; // 34px
@size-36: 2.25rem; // 36px
@size-35: 2.1875rem; // 35px
@size-38: 2.375rem; // 38px
@size-39: 2.4375rem; // 39px
@size-40: 2.5rem; // 40px
@size-42: 2.625rem; // 42px
@size-43: 2.6875rem; // 43px
@size-44: 2.75rem; // 44px
@size-45: 2.8125rem; // 45px
@size-46: 2.875rem; // 46px
@size-47: 2.9375rem; // 47px
@size-48: 3rem; // 48px
@size-50: 3.125rem; // 50px
@size-52: 3.25rem; // 52px
@size-54: 3.375rem; // 54px
@size-55: 3.4375rem; // 55px
@size-56: 3.5rem; // 56px
@size-57: 3.5625rem; // 57px
@size-58: 3.625rem; // 58px
@size-60: 3.75rem; // 60px
@size-62: 3.875rem; // 62px
@size-64: 4rem; // 64px
@size-66: 4.125rem; // 66px
@size-68: 4.25rem; // 68px
@size-70: 4.75rem; // 70px
@size-72: 4.5rem; // 72px
@size-74: 4.625rem; // 74px
@size-76: 4.75rem; // 76px
@size-78: 4.875rem; // 78px
@size-80: 5rem; // 80px
@size-82: 5.125rem; // 82px
@size-84: 5.25rem; // 84px
@size-85: 5.3125rem; // 85px
@size-86: 5.375rem; // 86px
@size-87: 5.4375rem; // 87px
@size-88: 5.5rem; // 88px
@size-90: 5.625rem; // 90px
@size-96: 6rem; // 96px
@size-100: 6.25rem; // 100px
@size-104: 6.5rem; // 104px
@size-106: 6.625rem; // 106px
@size-107: 6.6875rem; // 107px
@size-108: 6.75rem; // 108px
@size-110: 6.875rem; // 110px
@size-111: 6.9375rem; // 111px
@size-113: 7.0625rem; // 113px
@size-116: 7.25rem; // 116px
@size-120: 7.5rem; // 120px
@size-124: 7.75rem; // 124px
@size-125: 7.8125rem; // 125px
@size-128: 8rem; // 128px
@size-130: 8.125rem; // 130px
@size-136: 8.5rem; // 136px
@size-144: 9rem; // 144px
@size-145: 9.0625rem; // 145px
@size-147: 9.1875rem; // 147px
@size-148: 9.25rem; // 148px
@size-150: 9.375rem; // 150px
@size-153: 9.5625rem; // 153px
@size-155: 9.6875rem; // 155px
@size-160: 10rem; // 160px
@size-165: 10.3125rem; // 165px
@size-168: 10.5rem; // 168px
@size-173: 10.8125rem; // 173px
@size-178: 11.125rem; // 178px
@size-180: 11.25rem; // 180px
@size-192: 12rem; // 192px
@size-195: 12.1875rem; // 195px
@size-198: 12.375rem; // 198px
@size-200: 12.5rem; // 200px
@size-206: 12.875rem; // 206px
@size-210: 13.125rem; // 210px
@size-220: 13.75rem; // 220px
@size-222: 13.875rem; // 222px
@size-240: 15rem; // 240px
@size-242: 15.125rem; // 242px
@size-248: 15.5rem; // 248px
@size-250: 15.625rem; // 250px
@size-260: 16.25rem; // 260px
@size-269: 16.8125rem; // 269px
@size-276: 17.25rem; // 276px
@size-280: 17.5rem; // 280px
@size-288: 18rem; // 288px
@size-298: 18.625rem; // 298px
@size-320: 20rem; // 320px
@size-326: 20.375rem; // 326px
@size-340: 21.25rem; // 340px
@size-350: 21.875rem; // 350px
@size-360: 22.5rem; // 360px
@size-380: 23.75rem; // 380px
@size-395: 24.6875rem; // 395px
@size-400: 25rem; // 400px
@size-405: 25.3125rem; // 405px
@size-414: 25.875rem; // 414px
@size-420: 26.25rem; // 420px
@size-434: 27.125rem; // 434px
@size-472: 29.5rem; // 472px
@size-480: 30rem; // 480px
@size-512: 32rem; // 512px
@size-528: 33rem; // 528px
@size-540: 33.75rem; // 550px
@size-550: 34.375rem; // 550px
@size-560: 35rem; // 560px
@size-600: 37.5rem; // 600px
@size-720: 45rem; // 720px
@size-744: 46.5rem; // 744px
@size-750: 46.875rem; // 750px
@size-775: 48.4375rem; // 775px
@size-778: 48.625rem; // 778px
@size-800: 50rem; // 800px
@size-820: 51.25rem; // 820px
@size-912: 57rem; // 912px
// @size-950: 63rem; // 912px

// font-weights
@weight-light: 300; // 300
@weight-normal: 400; // 400
@weight-medium: 500; // 500
@weight-semi-bold: 600; // 600
@weight-bold: 700; // 700
@weight-extra-bold: 800; // 800
@weight-black: 900; // 900

// line-heights
@line-height-normal: normal; // this is 120% of the font-size. 10px = 12px
@line-height-paragraph: 1.25rem; // 20px for the 12px paragraph font-size
@line-height-small-spacing: 0.9375rem; // 15px for smallest text

// defaults`
.text-default {
  font-family: @Inter;
  font-style: normal;
  font-weight: @weight-normal;
  line-height: @line-height-normal;
  letter-spacing: -0.01rem;
}

.mono-default {
  .text-default;

  font-family: @Inter;
  font-feature-settings: "tnum";
}

// text sizes
.text-36 {
  .text-default;

  font-size: @size-36;
}

.text-36-medium {
  .text-36;

  font-weight: @weight-medium;
}

.text-36-semi-bold {
  .text-36;

  font-weight: @weight-semi-bold;
}

.text-36-bold {
  .text-36;

  font-weight: @weight-bold;
}

.text-36-extra-bold {
  .text-36;

  font-weight: @weight-extra-bold;
}

.text-36-black {
  .text-36;

  font-weight: @weight-black;
}

.text-30 {
  .text-default;

  font-size: @size-30;
}

.text-30-medium {
  .text-30;

  font-weight: @weight-medium;
}

.text-30-semi-bold {
  .text-30;

  font-weight: @weight-semi-bold;
}

.text-30-bold {
  .text-30;

  font-weight: @weight-bold;
}

.text-30-extra-bold {
  .text-30;

  font-weight: @weight-extra-bold;
}

.text-30-black {
  .text-30;

  font-weight: @weight-black;
}

.text-28 {
  .text-default;

  font-size: @size-28;
}

.text-28-medium {
  .text-28;

  font-weight: @weight-medium;
}

.text-28-semi-bold {
  .text-28;

  font-weight: @weight-semi-bold;
}

.text-28-bold {
  .text-28;

  font-weight: @weight-bold;
}

.text-28-extra-bold {
  .text-28;

  font-weight: @weight-extra-bold;
}

.text-28-black {
  .text-28;

  font-weight: @weight-black;
}

.text-24 {
  .text-default;

  font-size: @size-24;
}

.text-24-medium {
  .text-24;

  font-weight: @weight-medium;
}

.text-24-semi-bold {
  .text-24;

  font-weight: @weight-semi-bold;
}

.text-24-bold {
  .text-24;

  font-weight: @weight-bold;
}

.text-24-extra-bold {
  .text-24;

  font-weight: @weight-extra-bold;
}

.text-24-black {
  .text-24;

  font-weight: @weight-black;
}

.text-22 {
  .text-default;

  font-size: @size-22;
}

.text-22-medium {
  .text-22;

  font-weight: @weight-medium;
}

.text-22-semi-bold {
  .text-22;

  font-weight: @weight-semi-bold;
}

.text-22-bold {
  .text-22;

  font-weight: @weight-bold;
}

.text-22-extra-bold {
  .text-22;

  font-weight: @weight-extra-bold;
}

.text-22-black {
  .text-22;

  font-weight: @weight-black;
}

.text-20 {
  .text-default;

  font-size: @size-20;
}

.text-20-semi-bold {
  .text-20;

  font-weight: @weight-semi-bold;
}

.text-20-bold {
  .text-20;

  font-weight: @weight-bold;
}

.text-18 {
  .text-default;

  font-size: @size-18;
}

.text-18-medium {
  .text-18;

  font-weight: @weight-medium;
}

.text-18-semi-bold {
  .text-18;

  font-weight: @weight-semi-bold;
}

.text-18-bold {
  .text-18;

  font-weight: @weight-bold;
}

.text-18-extra-bold {
  .text-18;

  font-weight: @weight-extra-bold;
}

.text-18-black {
  .text-18;

  font-weight: @weight-black;
}

.text-16 {
  .text-default;

  font-size: @size-16;
}

.text-16-medium {
  .text-16;

  font-weight: @weight-medium;
}

.text-16-semi-bold {
  .text-16;

  font-weight: @weight-semi-bold;
}

.text-16-bold {
  .text-16;

  font-weight: @weight-bold;
}

.text-16-extra-bold {
  .text-16;

  font-weight: @weight-extra-bold;
}

.text-16-black {
  .text-16;

  font-weight: @weight-black;
}

.text-14 {
  .text-default;

  font-size: @size-14;
}

.text-14-medium {
  .text-14;

  font-weight: @weight-medium;
}

.text-14-semi-bold {
  .text-14;

  font-weight: @weight-semi-bold;
}

.text-14-bold {
  .text-14;

  font-weight: @weight-bold;
}

.text-14-extra-bold {
  .text-14;

  font-weight: @weight-extra-bold;
}

.text-14-black {
  .text-14;

  font-weight: @weight-black;
}

.text-14-paragraph {
  .text-14;

  line-height: 110%;
}

.text-14-medium-paragraph {
  .text-14-medium;

  line-height: 110%;
}

.text-14-bold-paragraph {
  .text-14-bold;

  line-height: 110%;
}

.text-13 {
  .text-default;

  font-size: @size-13;
}

.text-13-medium {
  .text-13;

  font-weight: @weight-medium;
}

.text-13-semi-bold {
  .text-13;

  font-weight: @weight-semi-bold;
}

.text-13-bold {
  .text-13;

  font-weight: @weight-bold;
}

.text-13-extra-bold {
  .text-13;

  font-weight: @weight-extra-bold;
}

.text-13-black {
  .text-13;

  font-weight: @weight-black;
}

.text-13-paragraph {
  .text-13;

  line-height: 140%;
}

.text-13-medium-paragraph {
  .text-13-medium;

  line-height: 140%;
}

.text-13-bold-paragraph {
  .text-13-bold;

  line-height: 140%;
}

.text-12 {
  .text-default;

  font-size: @size-12;
}

.text-12-medium {
  .text-12;

  font-weight: @weight-medium;
}

.text-12-semi-bold {
  .text-12;

  font-weight: @weight-semi-bold;
}

.text-12-bold {
  .text-12;

  font-weight: @weight-bold;
}

.text-12-extra-bold {
  .text-12;

  font-weight: @weight-extra-bold;
}

.text-12-black {
  .text-12;

  font-weight: @weight-black;
}

.text-12-paragraph {
  .text-12;

  line-height: 140%;
}

.text-12-medium-paragraph {
  .text-12-medium;

  line-height: 140%;
}

.text-12-bold-paragraph {
  .text-12-bold;

  line-height: 140%;
}

.text-11 {
  .text-default;

  font-size: @size-11;
}

.text-11-medium {
  .text-11;

  font-weight: @weight-medium;
}

.text-11-semi-bold {
  .text-11;

  font-weight: @weight-semi-bold;
}

.text-11-bold {
  .text-11;

  font-weight: @weight-bold;
}

.text-11-extra-bold {
  .text-11;

  font-weight: @weight-extra-bold;
}

.text-11-black {
  .text-11;

  font-weight: @weight-black;
}

.text-10 {
  .text-default;

  font-size: @size-10;
}

.text-10-medium {
  .text-10;

  font-weight: @weight-medium;
}

.text-10-semi-bold {
  .text-10;

  font-weight: @weight-semi-bold;
}

.text-10-bold {
  .text-10;

  font-weight: @weight-bold;
}

.text-10-extra-bold {
  .text-10;

  font-weight: @weight-extra-bold;
}

.text-10-black {
  .text-10;

  font-weight: @weight-black;
}

.text-10-paragraph {
  .text-10;

  line-height: 125%;
}

.text-9 {
  .text-default;

  font-size: @size-9;
}

.text-9-medium {
  .text-9;

  font-weight: @weight-medium;
}

.text-9-semi-bold {
  .text-9;

  font-weight: @weight-semi-bold;
}

.text-9-bold {
  .text-9;

  font-weight: @weight-bold;
}

.text-9-extra-bold {
  .text-9;

  font-weight: @weight-extra-bold;
}

.text-9-black {
  .text-9;

  font-weight: @weight-black;
}

.text-8 {
  .text-default;

  font-size: @size-8;
}

.text-8-medium {
  .text-8;

  font-weight: @weight-medium;
}

.text-8-semi-bold {
  .text-8;

  font-weight: @weight-semi-bold;
}

.text-8-bold {
  .text-8;

  font-weight: @weight-bold;
}

.text-8-extra-bold {
  .text-8;

  font-weight: @weight-extra-bold;
}

.text-8-black {
  .text-8;

  font-weight: @weight-black;
}

// mono sizes
.mono-24 {
  .mono-default;

  font-size: @size-24;
}

.mono-24-medium {
  .mono-24;

  font-weight: @weight-medium;
}

.mono-24-semi-bold {
  .mono-24;

  font-weight: @weight-semi-bold;
}

.mono-24-bold {
  .mono-24;

  font-weight: @weight-bold;
}

.mono-24-extra-bold {
  .mono-24;

  font-weight: @weight-extra-bold;
}

.mono-24-black {
  .mono-24;

  font-weight: @weight-black;
}

.mono-22 {
  .mono-default;

  font-size: @size-22;
}

.mono-22-medium {
  .mono-22;

  font-weight: @weight-medium;
}

.mono-22-semi-bold {
  .mono-22;

  font-weight: @weight-semi-bold;
}

.mono-22-bold {
  .mono-22;

  font-weight: @weight-bold;
}

.mono-22-extra-bold {
  .mono-22;

  font-weight: @weight-extra-bold;
}

.mono-22-black {
  .mono-22;

  font-weight: @weight-black;
}

.mono-20 {
  .mono-default;

  font-size: @size-20;
}

.mono-20-medium {
  .mono-20;

  font-weight: @weight-medium;
}

.mono-20-semi-bold {
  .mono-20;

  font-weight: @weight-semi-bold;
}

.mono-20-bold {
  .mono-20;

  font-weight: @weight-bold;
}

.mono-20-extra-bold {
  .mono-20;

  font-weight: @weight-extra-bold;
}

.mono-20-black {
  .mono-20;

  font-weight: @weight-black;
}

.mono-14 {
  .mono-default;

  font-size: @size-14;
}

.mono-14-medium {
  .mono-14;

  font-weight: @weight-medium;
}

.mono-14-semi-bold {
  .mono-14;

  font-weight: @weight-semi-bold;
}

.mono-14-bold {
  .mono-14;

  font-weight: @weight-bold;
}

.mono-14-extra-bold {
  .mono-14;

  font-weight: @weight-extra-bold;
}

.mono-14-black {
  .mono-14;

  font-weight: @weight-black;
}

.mono-12 {
  .mono-default;

  font-size: @size-12;
}

.mono-12-medium {
  .mono-12;

  font-weight: @weight-medium;
}

.mono-12-semi-bold {
  .mono-12;

  font-weight: @weight-semi-bold;
}

.mono-12-bold {
  .mono-12;

  font-weight: @weight-bold;
}

.mono-12-extra-bold {
  .mono-12;

  font-weight: @weight-extra-bold;
}

.mono-12-black {
  .mono-12;

  font-weight: @weight-black;
}

.mono-11 {
  .mono-default;

  font-size: @size-11;
}

.mono-11-medium {
  .mono-11;

  font-weight: @weight-medium;
}

.mono-11-semi-bold {
  .mono-11;

  font-weight: @weight-semi-bold;
}

.mono-11-bold {
  .mono-11;

  font-weight: @weight-bold;
}

.mono-11-extra-bold {
  .mono-11;

  font-weight: @weight-extra-bold;
}

.mono-11-black {
  .mono-11;

  font-weight: @weight-black;
}

.mono-10 {
  .mono-default;

  font-size: @size-10;
}

.mono-10-medium {
  .mono-10;

  font-weight: @weight-medium;
}

.mono-10-semi-bold {
  .mono-10;

  font-weight: @weight-semi-bold;
}

.mono-10-bold {
  .mono-10;

  font-weight: @weight-bold;
}

.mono-10-extra-bold {
  .mono-10;

  font-weight: @weight-extra-bold;
}

.mono-10-black {
  .mono-10;

  font-weight: @weight-black;
}

.mono-9 {
  .mono-default;

  font-size: @size-9;
}

.mono-9-medium {
  .mono-9;

  font-weight: @weight-medium;
}

.mono-9-semi-bold {
  .mono-9;

  font-weight: @weight-semi-bold;
}

.mono-9-bold {
  .mono-9;

  font-weight: @weight-bold;
}

.mono-9-extra-bold {
  .mono-9;

  font-weight: @weight-extra-bold;
}

.mono-9-black {
  .mono-9;

  font-weight: @weight-black;
}

// Site Wide Typography:
h1 {
  font-size: @size-30;
  font-weight: @weight-normal;
  line-height: @size-35;
  margin-bottom: @size-20;

  @media @breakpoint-mobile-small {
    font-size: @size-24;
    line-height: @size-30;
  }
}