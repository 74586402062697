@import (reference) '~assets/styles/shared';

.TopBanner {
  .TertiaryBorderRadius;

  min-height: @size-148;
  height: @size-148;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/banner-app.png"); // 1264px // 1023px // 959px

  >h1 {
    .text-36-semi-bold;

    margin: 0;
    color: var(--primary-bg);
  }

  @media @breakpoint-mobile-tablet {
    min-height: @size-85;
    border-radius: @size-6;
    height: @size-85;
    background-position-x: 40%;

    >h1 {
      .text-24-semi-bold;
    }
  }

  @media @breakpoint-mobile {
    background-position-x: 60%;
    background-image: url("../../assets/images/banner-app.png");

    >h1 {
      .text-16-semi-bold;
    }
  }
}

:root[theme="DARK"] {
  .TopBanner>h1 {
    color: var(--primary-text);
  }
}