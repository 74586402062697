.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
  text-align: center;
  /* Center text */
}

.stylishButton {
  background-color: #101110; // Green background
  color: white; // White text
  padding: 15px 32px; // Larger padding for bigger buttons
  text-align: center; // Centered text
  text-decoration: none; // No underline
  display: inline-block; // Inline block for proper spacing
  font-size: 16px; // Larger font size
  margin: 4px 2px; // Margin for spacing
  cursor: pointer; // Pointer cursor on hover
  border: none; // No border
  border-radius: 4px; // Rounded corners
  transition: background-color 0.3s; // Smooth transition for hover effect

  &:hover {
    background-color: #868d86; // Darker green on hover
  }
}

.transactionLink {
  color: #1E90FF; // Bright blue color
  font-weight: bold; // Bold text
  text-decoration: none; // No underline
  transition: color 0.3s; // Smooth transition for hover effect

  &:hover {
    color: #FF4500; // Change color on hover (orange)
    text-decoration: underline; // Underline on hover
  }
}