@import (reference) '~assets/styles/shared';

.LogoBeta {
  width: @size-276;
  height: @size-72;
  align-items: center;
  display: flex;
  align-content: center;
  padding-top: 3.188em;

  >svg {
    width: 100%;
    height: 100%;
  }
}


.v2Logo {
  width: @size-96;
  height: @size-32;
  align-items: center;
  display: flex;
  align-content: center;

  >svg {
    width: 100%;
    height: 100%;

    &:first-of-type {



      @media @breakpoint-mobile {
        display: block;
      }
    }
  }


}

@media @breakpoint-mobile-tablet {
  .LogoBeta {
    width: @size-72;
    height: @size-40;
  }

  .v2Logo {
    >svg {
      width: @size-40;
      height: @size-40;
    }
  }
}

.LogoImage {
  max-width: 100%;
  height: auto;
}