@import (reference) '~assets/styles/shared';

.Activity {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: 100%;

  > span:first-of-type {
    .text-20-bold;

    color: var(--primary-text);
    text-transform: capitalize;
  }

  > span:nth-of-type(2) {
    .text-14-semi-bold;

    color: var(--secondary-text);
    margin-top: @size-32;
  }

  > div {
    > div {
      > span {
        .text-14-semi-bold;

        color: var(--secondary-text);
        margin-bottom: @size-12;
        margin-top: @size-32;
        display: flex;
      }
    }
  }

  > div:last-of-type {
    justify-content: center;
    margin-top: @size-32;
  }
}

.ActivityCard {
  .ThemeBorder;
  .HoverDropShadow;

  margin-bottom: @size-6;
  padding: @size-16;
  display: grid;
  grid-template-columns: 1fr auto auto;
  // cursor: pointer;

  &:hover {
    background-color: var(--primary-bg);
  }

  > div,
  > span,
  > a:first-of-type {
    display: flex;
    align-items: center;
  }

  > div.type {
    .text-10-semi-bold;

    color: var(--primary-text);
    text-transform: uppercase;
  }

  > div.value {
    .text-12-semi-bold;

    color: var(--primary-text);
  }

  > div.icon {
    > svg {
      width: @size-16;
      height: @size-16;
      margin-left: @size-10;
    }
  }

  > a:first-of-type > span.description {
    .text-12;

    color: var(--primary-text);
    grid-column: 1 / span 3;
    margin: @size-8 0;
  }

  > div.subheader {
    .text-12-semi-bold;

    grid-column: 1 / span 3;
    color: var(--primary-text);
    margin-bottom: @size-4;
  }

  > div.time {
    .text-12-semi-bold;

    color: var(--secondary-text);
  }

  > a:not(:first-of-type) {
    .text-12-semi-bold;

    grid-column: 2 / span 2;
    text-align: right;
    text-decoration: none;
    color: var(--secondary-text);
    justify-content: flex-end;

    &:hover {
      color: var(--primary-text);
    }
  }
}

@media @breakpoint-mobile-tablet {
  .Activity {
    > span:first-of-type {
      display: none;
    }

    > div > div:first-of-type > span {
      margin-top: 0;
    }
  }

  .ActivityCard {
    background-color: var(--primary-bg);
  }
}
