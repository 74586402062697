@import (reference) '~assets/styles/shared';
@import (reference) '~modules/common/buttons.styles.less';

.Slippage {
  margin-top: @size-12;
  cursor: pointer;
  overflow: hidden;
  height: @size-20;
  transition: height 100ms ease-in-out, opacity 100ms ease-in-out;

  &.showSelection {
    height: @size-60;

    > ul {
      opacity: 1;
    }

    &.HasError {
      height: @size-85;
    }
  }

  > label {
    .text-12-semi-bold;

    color: var(--secondary-text);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    cursor: pointer;

    > span {
      cursor: pointer;
    }

    > span:first-of-type {
      margin-left: @size-5;
    }

    > span:nth-of-type(2) {
      .text-12-semi-bold;

      flex: 1;
      display: flex;
      justify-content: flex-end;
      color: var(--primary-text);
      margin-right: @size-8;
    }
  }

  > ul {
    display: flex;
    flex-flow: row nowrap;
    margin-top: @size-12;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;

    > div {
      .ThemeBorder;
      display: flex;

      > li > button {
        border-color: transparent;
      }
    }
    > li {
      margin-left: @size-8;
    }

    > div > li > button {
      &:hover,
      &:not(:disabled):not(.Disabled):hover {
        color: var(--primary-text);
        border-color: transparent;
        background-color: transparent;
      }

      &:active,
      &:focus,
      &:not(:disabled):not(.Disabled)[class*='Selected'],
      &:not(:disabled):not(.Disabled):focus,
      &[class*='Selected'] {
        color: var(--primary-text);
        background: transparent;
        border: transparent;
      }
    }

    > div {
      position: relative;

      > li {
        z-index: 2;
      }

      > div {
        border: 1px solid var(--secondary-text);
        background-color: var(--tertiary-bg);
        min-height: @size-27;
        height: @size-27;
        margin-top: -@size-1;
        margin-bottom: -@size-1;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: @size-42;
        border-radius: @size-8;
        z-index: 1;
        transition: all 0.1s linear 0s;
        margin-left: -@size-1;

        &.first {
          transform: translateX(94%);
        }

        &.second {
          transform: translateX(170%);
        }

        &.third {
          transform: translateX(250%);
        }

        &.none {
          display: none;
        }
      }
    }

    > li > div {
      .ThemeBorder;

      background-color: transparent;
      box-sizing: border-box;
      display: flex;
      flex-flow: row nowrap;
      padding: @size-5 @size-8;
      max-height: @size-27;
      min-height: @size-27;
      width: @size-80;
      align-items: center;
      justify-content: center;

      > input[type='number'] {
        -moz-appearance: textfield;
        .text-12-semi-bold;

        background: transparent;
        color: var(--secondary-text);
        padding: 0;
        text-align: right;
        outline-style: none;
        border: none;
        min-width: @size-45;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        &:focus {
          color: var(--primary-text);
          outline-style: none;
          border: none;
        }
      }

      > span {
        .text-12-semi-bold;

        color: var(--primary-text);
        margin-left: @size-4;
      }

      &[class*='Selected'] {
        background-color: var(--tertiary-bg);
        border: @size-1 solid var(--secondary-text);

        > input {
          color: var(--primary-text);
        }

        &:not(:disabled):not(.Disabled) {
          &:hover,
          &:active {
            background-color: var(--tertiary-bg);
            border: @size-1 solid var(--secondary-text);
          }
        }
      }
    }
  }

  > span {
    .text-12-semi-bold;

    color: var(--failed);
    display: flex;
    justify-content: flex-end;
    margin-top: @size-8;
  }
}

&.Selected {
  display: flex;
}
