@import (reference) "~assets/styles/shared";

.ModalView {
  background-color: rgba(var(--primary-text-rgb), 0.4);
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: @mask-modal;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    .ThemeBorder;
    .TertiaryBorderRadius;

    align-items: center;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    width: fit-content;
    min-width: @size-288;
    margin: 0 @size-16;
    background: var(--primary-bg);
    overflow: hidden;
  }

  @media @breakpoint-mobile-tablet {
    width: 100%;
    height: 100%;

    > div {
      max-width: 100%;
      max-height: calc(100% - @size-48);
      margin: 0 @size-16;
    }
  }
}

:root[theme="DARK"] {
  .ModalView {
    background-color: rgba(var(--primary-bg-rgb), 0.9);
  }
}

.ModalConfirmTransaction {
  .Container;

  max-width: 395px;
  min-width: 350px;

  > main {
    padding-top: @size-16;

    > section:not(:last-of-type) {
      border-bottom: @size-1 solid var(--theme-border);
      padding-bottom: @size-16;

      &:first-of-type.TargetDescription {
        grid-gap: @size-4;
        > span:first-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.TargetDescription {
  > span:first-of-type,
  > span:nth-of-type(3) {
    .text-14-semi-bold;

    color: var(--primary-text);
    margin-bottom: 0;
  }

  > span:nth-of-type(2) {
    display: grid;
    grid-gap: @size-4;

    > span:first-of-type {
      .text-14-semi-bold;

      color: var(--primary-text);
      line-height: 140%;

      > span:first-of-type {
        .limit-lines(1);
      }

      > span:last-of-type {
        .limit-lines(2);
      }

      > span:first-of-type:last-of-type {
        .limit-lines(3);
      }
    }

    > span:last-of-type {
      .text-12;

      color: var(--secondary-text);
      white-space: nowrap;
      word-break: none;
      display: inline-block;
      text-transform: capitalize;
    }
  }
}

.LineBreak {
  border-bottom: @size-1 solid var(--theme-border);
  padding-bottom: @size-24;

  > span {
    margin-bottom: @size-12;
  }
}

.SmallLabel {
  .text-16-semi-bold;

  color: var(--primary-text);
  display: flex;

  > span {
    margin-left: @size-4;
  }
}

.Container {
  > div[class*="Header"] {
    margin: 0;
    padding: @size-24;
  }

  > main {
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: @size-24;
    padding: @size-24;

    > section:not([class*="WarningBanner"]) {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      grid-gap: @size-8;

      > span:first-of-type {
        margin-bottom: @size-4;
      }

      > div {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        grid-gap: @size-8;
        margin: 0;

        > div:not(:first-of-type) {
          margin-top: 0;
        }

        &:not(:last-of-type) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.Header {
  .text-20-bold;
  .TertiaryTopBorderRadius;

  align-items: center;
  flex: 1;
  border-bottom: @size-1 solid var(--theme-border);
  min-height: @size-72;
  padding: 0 @size-24 0 @size-32;
  width: 100%;
  margin: 0;
  z-index: @above-all-content;
  height: @size-72;
  max-height: @size-72;
  color: var(--secondary-text);
  display: grid;
  grid-template-columns: 1fr 0fr 0fr;
  grid-gap: @size-8;

  > span {
    .text-20-bold;

    color: var(--primary-text);
    text-transform: capitalize;
    flex: 1;

    > svg {
      margin-right: @size-8;
    }

    &:hover {
      color: var(--primary-text);

      > svg {
        > path {
          fill: var(--primary-text);
        }
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: @size-32;

    > span:first-of-type {
      .text-16;

      text-transform: capitalize;
      color: var(--secondary-text);
    }

    > span:last-of-type {
      .text-20-semi-bold;

      color: var(--primary-text);
      margin-top: @size-4;
    }
  }

  > button:last-of-type {
    background: transparent;
    outline: 0;
    border: 0;
    cursor: pointer;
    display: flex;

    > svg > path {
      fill: var(--primary-text);
    }
  }
}

@media @breakpoint-mobile-tablet {
  .Header {
    > span {
      .text-16-bold;
      
      white-space: nowrap;
    }
  }
}

.FooterText {
  .TertiaryBottomBorderRadius;
  .text-14;

  color: var(--primary-text);
  padding: @size-24;
  background-color: var(--secondary-bg);
  border-top: @size-1 solid var(--theme-border);
  line-height: @size-22;
  margin-bottom: -@size-24;
  margin-left: -@size-24;
  margin-right: -@size-24;

  > span:first-of-type {
    margin-bottom: @size-12;
    display: flex;
    font-weight: 600;
  }

  > a:first-of-type {
    .text-16-semi-bold;

    text-transform: unset;
    text-decoration: underline;
    color: var(--secondary-text);
  }
}

.MarketTitle {
  .ThemeBorder;

  padding: @size-16;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-bg);

  > span:first-of-type {
    .text-14-semi-bold;

    margin-bottom: @size-8;
    color: var(--primary-text);
  }

  > span:not(:first-of-type) {
    display: flex;
    flex-direction: column;

    > span:first-of-type {
      .text-12;

      display: flex;
      flex-direction: column;
      color: var(--primary-text);
      margin-bottom: @size-2;
    }

    > span:nth-of-type(2) {
      .text-10-bold;

      color: var(--secondary-text);
    }
  }
}

.HeaderLink {
  cursor: pointer;
}

.ErrorBorder {
  border: @size-1 solid var(--failed); 
  svg {
    path {
      fill: var(--failed)
    }
  }
}