@import (reference) '~assets/styles/shared';

.EmptyMarketsMessage {
  .EmptyMessage;

  background: var(--primary-bg);
  padding: @size-20 @size-16;
  display: flex;
  text-align: center;
  border-radius: @size-8;
  grid-column: span 3;
}

.SearchButton {
  .ThemeBorder;

  padding: @size-12 @size-16;

  &.Selected {
    background: var(--tertiary-bg);
  }
}

.MarketsView {
  display: flex;
  flex-flow: column nowrap;
  padding: @size-40 @size-88 @size-48;

  > ul {
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    margin: @size-40 0 @size-16;

    > div {
      margin-right: @size-20;

      &:last-of-type {
        margin-right: 0;
      }
    }

    > button {
      margin-left: auto;
    }
  }

  > button {
    margin: @size-12 0;
  }

  > section {
    display: grid;
    grid-gap: @size-24;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 0fr);
    flex: 1;
    margin-bottom: @size-40;
    margin-top: @size-24;
  }

  > span {
    margin-top: @size-24;
  }

  > div:last-of-type:not(:first-of-type):not(.SubCategoriesFilter) {
    justify-content: center;
  }

  > div[class*='SearchInput'] {
    overflow: hidden;
    height: 0px;
    transition: height 100ms ease-in-out, opacity 100ms ease-in-out;
    opacity: 0;
  }

  &.SearchOpen {
    > div[class*='SearchInput'] {
      height: @size-48;
      opacity: 1;
      margin-bottom: @size-16;
    }
  }
}

@media @breakpoint-mobile-tiny {
  .MarketsView {
    padding-bottom: @size-48;

    > section {
      grid-template-columns: 1fr 1fr;
      grid-gap: @size-16;
      margin-top: 0;
    }

    > span {
      margin-top: @size-16;
    }

    > div:nth-of-type(2) {
      display: flex;
      margin: @size-4 0;

      > button:first-of-type {
        flex: 1;
        margin-right: @size-8;
      }
    }

    &.SearchOpen {
      > div[class*='SearchInput'] {
        margin-bottom: @size-16;
      }
    }
  }
}

@media @breakpoint-mobile-tablet {
  .MarketsView {
    padding: @size-16;
    padding-bottom: @size-48;

    > div:nth-of-type(2) {
      display: flex;
      margin: @size-16 0;

      > button:first-of-type {
        flex: 1;
        margin-right: @size-8;
      }
    }

    > ul {
      display: none;
    }
  }
}

@media @breakpoint-mobile {
  .MarketsView {
    > section {
      grid-template-columns: 1fr;
    }
  }
}

.SubCategoriesFilter {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: -@size-8;
}

.SubCategoryFilterButton {
  .text-12-semi-bold;

  background: transparent;
  color: var(--secondary-text);
  border: @size-1 solid var(--theme-border);
  border-radius: @size-8;
  padding: @size-4 @size-8;
  height: @size-25;
  min-height: @size-25;
  max-height: @size-25;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: @size-8;

  &:not(:last-of-type) {
    margin-right: @size-12;
  }

  > svg {
    height: @size-16;
    width: @size-16;
    margin-right: @size-4;

    > path[fill],
    > g > path[fill] {
      fill: var(--secondary-text);
    }

    > line,
    > path[stroke],
    > g > path[stroke] {
      stroke: var(--secondary-text);
    }
  }

  &:hover {
    border: @size-1 solid var(--secondary-text);
    cursor: pointer;
  }

  &.selectedFilterCategory {
    color: var(--sports-color);
    border: @size-1 solid var(--sports-color);

    &:hover {
      border: @size-1 solid var(--sports-color);
    }
  

    > svg {
      > path[fill],
      > g > path[fill] {
        fill: var(--sports-color);
      }

      > line,
      > path[stroke],
      > g > path[stroke] {
        stroke: var(--sports-color);
      }
    }
  }
}

@media @breakpoint-mobile-tiny {
  .SubCategoriesFilter {
    margin-bottom: @size-8;
  }
}

@media @breakpoint-mobile-tablet { 
  .SubCategoryFilterButton {
    &:not(:last-of-type) {
      margin-right: @size-8;
    }
  }
}