@import (reference) '~assets/styles/shared';

.WalletIcon {
  display: flex;
  align-items: center;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > img {
    max-height: @size-16;
  }

  > button {
    margin-left: @size-4;
  }
}
