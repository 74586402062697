// NOTE -- There is import order dependence, so please don't reorder before checking inheritence
// This is so that there is not circular dependencies amoung these stylesheets
@import '_colors';
@import '_typography';
@import '_misc_redesign';
@import '_responsive';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: @size-16;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--secondary-bg);
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  color: var(--primary-text);
  font-family: @Inter;
  font-size: @size-16;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

label {
  color: var(--primary-text);
  display: block;
  margin-bottom: @size-10;

  &[for] {
    cursor: pointer;
  }
}

a {
  text-decoration: none;
  color: unset;

  &[disabled] {
    cursor: not-allowed;
  }
}

input,
textarea {
  border: @size-1 solid transparent;
  color: var(--primary-text);
  display: block;
  font-family: @Inter;
  padding: @size-12 @size-16;
  width: 100%;

  &:disabled {
    cursor: default;
  }

  &::placeholder {
    color: var(--secondary-text);
    font-family: @Inter;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

:global .App--windowsScrollBars {
  ::-webkit-scrollbar {
    width: @size-6;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary-text);
    border-radius: @size-100;
  }
}

.EmptyMessage {
  .text-12;

  color: var(--primary-text);
  padding: @size-20 0;
  background-color: var(--primary-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: @size-8;
  border-bottom-left-radius: @size-8;
}

.ShimmerEmptyBlock {
  border-radius: @size-8;
  background: var(--secondary-bg);
  background-image: linear-gradient(
    to right,
    var(--secondary-bg) 0%,
    var(--tertiary-bg) 20%,
    var(--secondary-bg) 40%,
    var(--secondary-bg) 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation: shimmer 2s infinite;

  @keyframes shimmer {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
}