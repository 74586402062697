:root {
  //text
  --primary-text: #15171a;
  --primary-text-rgb: 21, 23, 26;
  --secondary-text: #8b959e;
  --secondary-text-rgb: 139, 149, 158;
  --tertiary-text: #8b959e;
  --tertiary-text-rgb: 139, 149, 158;
  --inverted-primary-text: #ffffff;
  --inverted-primary-text-rgb: 255, 255, 255;
  // backgrounds
  --primary-bg: #ffffff;
  --primary-bg-rgb: 255, 255, 255;
  --secondary-bg: #f6f7f8;
  --secondary-bg-rgb: 246, 247, 248;
  --tertiary-bg: #edeff1;
  --tertiary-bg-rgb: 237, 239, 241;
  --quaternary-bg: #fafbfc;
  --quaternary-bg-rgb: 250, 251, 252;
  --inverted-primary-bg: #15171a;
  --inverted-primary-bg-rgb: 21, 23, 26;
  // misc
  --theme-border: #dbe1e7;
  --theme-border-rgb: 219, 225, 231;
  --theme-focus: #1b91ff;
  --theme-focus-rgb: 27, 145, 255;
  // status
  --failed: #ff3e13;
  --failed-rgb: 255, 62, 19;
  --warning: #f59300;
  --warning-rgb: 245, 147, 0;
  --success: #0cce8d;
  --success-rgb: 12, 206, 141;
  // trading
  --buy: #0cce8d;
  --buy-rgb: 12, 206, 141;
  --sell: #ff7d5e;
  --sell-rgb: 255, 125, 94;

  // outcomes
  --theme-outcome-0: #58586b;
  --theme-outcome-1: #0cce8d;
  --theme-outcome-2: #ff7d5e;
  --theme-outcome-3: #73d2de;
  --theme-outcome-4: #218380;
  --theme-outcome-5: #ffbc42;
  --theme-outcome-6: #d81159;
  --theme-outcome-7: #1f71b5;

  --dark-button-theme-color: var(--primary-text);
  --light-button-theme-color: var(--primary-bg);
  --tiny-dark-button-theme-color: var(--secondary-text);
  --tiny-light-button-theme-color: var(--theme-border);
  --theme-focus-box-shadow: 0 0 0 @size-1 var(--theme-focus);
  --primary-button-theme-color: var(--success);
  --primary-button-theme-background-color: rgba(var(--success-rgb), 0.15);
  --inverted-primary-button-theme-background-color: rgba(var(--success-rgb), 0.3);
  --secondary-button-theme-background-color: var(--secondary-bg);
  --inverted-secondary-button-theme-background-color: var(--tertiary-bg);

  // This example is going to assume sportsbook values for now.
  --primary-button-background: var(--primary-button-theme-background-color);
  --primary-button-border-color: var(--primary-button-theme-color);
  --primary-button-border: @size-1 solid var(--primary-button-border-color);
  --primary-button-text-color: var(--dark-button-theme-color);

  --primary-button-background-hover: var(--inverted-primary-button-theme-background-color);
  --primary-button-border-color-hover: var(--primary-button-theme-color);
  --primary-button-border-hover: @size-1 solid var(--primary-button-border-color-hover);
  --primary-button-text-color-hover: var(--dark-button-theme-color);

  --primary-button-background-focus: var(--primary-button-theme-background-color);
  --primary-button-border-color-focus: var(--theme-focus);
  --primary-button-border-focus: @size-1 solid var(--primary-button-border-color-focus);
  --primary-button-text-color-focus: var(--dark-button-theme-color);

  --primary-button-background-pressed: var(--inverted-primary-button-theme-background-color);
  --primary-button-border-color-pressed: var(--primary-button-theme-color);
  --primary-button-border-pressed: @size-1 solid var(--primary-button-border-color-pressed);
  --primary-button-text-color-pressed: var(--dark-button-theme-color);

  --inverted-primary-button-background: var(--inverted-primary-button-theme-background-color);
  --inverted-primary-button-border-color: var(--primary-button-theme-color);
  --inverted-primary-button-border: @size-1 solid var(--inverted-primary-button-border-color);
  --inverted-primary-button-text-color: var(--light-button-theme-color);

  --inverted-primary-button-background-hover: var(--light-button-theme-color);
  --inverted-primary-button-border-color-hover: var(--light-button-theme-color);
  --inverted-primary-button-border-hover: @size-1 solid var(--inverted-primary-button-border-color-hover);
  --inverted-primary-button-text-color-hover: var(--dark-button-theme-color);

  --inverted-primary-button-background-focus: var(--inverted-primary-button-theme-background-color);
  --inverted-primary-button-border-color-focus: var(--theme-focus);
  --inverted-primary-button-border-focus: @size-1 solid var(--inverted-primary-button-border-color-focus);
  --inverted-primary-button-text-color-focus: var(--light-button-theme-color) f;

  --inverted-primary-button-background-pressed: var(--light-button-theme-color);
  --inverted-primary-button-border-color-pressed: var(--light-button-theme-color);
  --inverted-primary-button-border-pressed: @size-1 solid var(--inverted-primary-button-border-color-pressed);
  --inverted-primary-button-text-color-pressed: var(--dark-button-theme-color);

  // This example is going to assume sportsbook values for now.
  --secondary-button-background: var(--secondary-button-theme-background-color);
  --secondary-button-border-color: var(--tiny-light-button-theme-color);
  --secondary-button-border: @size-1 solid var(--secondary-button-border-color);
  --secondary-button-text-color: var(--dark-button-theme-color);

  --secondary-button-background-hover: var(--inverted-secondary-button-theme-background-color);
  --secondary-button-border-color-hover: var(--tiny-light-button-theme-color);
  --secondary-button-border-hover: @size-1 solid var(--secondary-button-border-color-hover);
  --secondary-button-text-color-hover: var(--dark-button-theme-color);

  --secondary-button-background-focus: var(--secondary-button-theme-background-color);
  --secondary-button-border-color-focus: var(--theme-focus);
  --secondary-button-border-focus: @size-1 solid var(--secondary-button-border-color-focus);
  --secondary-button-text-color-focus: var(--dark-button-theme-color);

  --secondary-button-background-pressed: var(--inverted-secondary-button-theme-background-color);
  --secondary-button-border-color-pressed: var(--tiny-light-button-theme-color);
  --secondary-button-border-pressed: @size-1 solid var(--secondary-button-border-color-pressed);
  --secondary-button-text-color-pressed: var(--dark-button-theme-color);

  --inverted-secondary-button-background: transparent;
  --inverted-secondary-button-border-color: var(--light-button-theme-color);
  --inverted-secondary-button-border: @size-1 solid var(--inverted-secondary-button-border-color);
  --inverted-secondary-button-text-color: var(--light-button-theme-color);

  --inverted-secondary-button-background-hover: var(--light-button-theme-color);
  --inverted-secondary-button-border-color-hover: var(--light-button-theme-color);
  --inverted-secondary-button-border-hover: @size-1 solid var(--inverted-secondary-button-border-color-hover);
  --inverted-secondary-button-text-color-hover: var(--dark-button-theme-color);

  --inverted-secondary-button-background-focus: transparent;
  --inverted-secondary-button-border-color-focus: var(--theme-focus);
  --inverted-secondary-button-border-focus: @size-1 solid var(--inverted-secondary-button-border-color-focus);
  --inverted-secondary-button-text-color-focus: var(--light-button-theme-color);

  --inverted-secondary-button-background-pressed: var(--light-button-theme-color);
  --inverted-secondary-button-border-color-pressed: var(--light-button-theme-color);
  --inverted-secondary-button-border-pressed: @size-1 solid var(--inverted-secondary-button-border-color-pressed);
  --inverted-secondary-button-text-color-pressed: var(--dark-button-theme-color);

  // This example is going to assume sportsbook values for now.
  --tiny-button-background: transparent;
  --tiny-button-border-color: var(--tiny-light-button-theme-color);
  --tiny-button-border: @size-1 solid var(--tiny-button-border-color);
  --tiny-button-text-color: var(--tiny-dark-button-theme-color);

  --tiny-button-background-hover: var(--light-button-theme-color);
  --tiny-button-border-color-hover: var(--tiny-dark-button-theme-color);
  --tiny-button-border-hover: @size-1 solid var(--tiny-button-border-color-hover);
  --tiny-button-text-color-hover: var(--tiny-dark-button-theme-color);

  --tiny-button-background-focus: var(--light-button-theme-color);
  --tiny-button-border-color-focus: var(--tiny-dark-button-theme-color);
  --tiny-button-border-focus: @size-1 solid var(--tiny-button-border-color-focus);
  --tiny-button-text-color-focus: var(--tiny-dark-button-theme-color);

  --tiny-button-background-pressed: var(--inverted-secondary-button-theme-background-color);
  --tiny-button-border-color-pressed: var(--dark-button-theme-color);
  --tiny-button-border-pressed: @size-1 solid var(--tiny-button-border-color-pressed);
  --tiny-button-text-color-pressed: var(--dark-button-theme-color);

  --tiny-button-background-selected: var(--inverted-secondary-button-theme-background-color);
  --tiny-button-border-selected: var(--dark-button-theme-color);
  --tiny-button-selected-color: var(--dark-button-theme-color);

  --inverted-tiny-button-background: var(--light-button-theme-color);
  --inverted-tiny-button-border-color: var(--tiny-light-button-theme-color);
  --inverted-tiny-button-border: @size-1 solid var(--inverted-tiny-button-border-color);
  --inverted-tiny-button-text-color: var(--tiny-dark-button-theme-color);

  --inverted-tiny-button-background-hover: var(--light-button-theme-color);
  --inverted-tiny-button-border-color-hover: var(--dark-button-theme-color);
  --inverted-tiny-button-border-hover: @size-1 solid var(--inverted-tiny-button-border-color-hover);
  --inverted-tiny-button-text-color-hover: var(--tiny-dark-button-theme-color);

  --inverted-tiny-button-background-focus: var(--light-button-theme-color);
  --inverted-tiny-button-border-color-focus: var(--theme-focus);
  --inverted-tiny-button-border-focus: @size-1 solid var(--inverted-tiny-button-border-color-focus);
  --inverted-tiny-button-text-color-focus: var(--tiny-dark-button-theme-color);

  --inverted-tiny-button-background-pressed: var(--dark-button-theme-color);
  --inverted-tiny-button-border-color-pressed: var(--dark-button-theme-color);
  --inverted-tiny-button-border-pressed: @size-1 solid var(--inverted-tiny-button-border-color-pressed);
  --inverted-tiny-button-text-color-pressed: var(--light-button-theme-color);

  --button-disabled-opacity: 0.5;
  --theme-button-border-radius: @size-8;

  --normal-button-height: @size-48;
  --normal-button-padding: @size-13 @size-16;
  --normal-button-svg-height: @size-20;
  --normal-button-svg-width: @size-20;
  --normal-button-font-size: @size-16;
  --normal-button-font-weight: 700;
  --normal-button-flex-flow: row-reverse nowrap;
  --normal-button-flex-flow-reverse: row wrap;
  --normal-button-icon-margin: 0 @size-4 0 0;
  --normal-button-icon-margin-reverse: 0 0 0 @size-4;
  --normal-button-text-transform: capitalize;

  --small-button-height: @size-36;
  --small-button-padding: @size-12 @size-16;
  --small-button-svg-height: @size-16;
  --small-button-svg-width: @size-16;
  --small-button-font-size: @size-14;
  --small-button-font-weight: 600;
  --small-button-text-transform: capitalize;

  --tiny-button-height: @size-25;
  --tiny-button-padding: @size-3 @size-8;
  --tiny-button-svg-height: @size-16;
  --tiny-button-svg-width: @size-16;
  --tiny-button-font-size: @size-12;
  --tiny-button-font-weight: 600;
  --tiny-button-text-transform: none;
}

:root[theme="DARK"] {
  //text
  --primary-text: #f4f3f4;
  --primary-text-rgb: 244, 243, 244;
  --secondary-text: #b3b3b3;
  --secondary-text-rgb: 179, 179, 179;
  --tertiary-text: #b3b3b3;
  --tertiary-text-rgb: 179, 179, 179;
  --inverted-primary-text: #1b1a1e;
  --inverted-primary-text-rgb: 27, 26, 30;
  // backgrounds
  --primary-bg: #252429;
  --primary-bg-rgb: 37, 36, 41;
  --secondary-bg: #1b1a1e;
  --secondary-bg-rgb: 27, 26, 30;
  --tertiary-bg: #201f23;
  --tertiary-bg-rgb: 32, 31, 35;
  --quaternary-bg: #161618;
  --quaternary-bg-rgb: 22, 22, 24;
  --inverted-primary-bg: #f4f3f4;
  --inverted-primary-bg-rgb: 244, 243, 244;
  // misc
  --theme-border: #383838;
  --theme-border-rgb: 56, 56, 56;
  --theme-focus: #1b91ff;
  --theme-focus-rgb: 27, 145, 255;
  // status
  --failed: #ff3e13;
  --failed-rgb: 255, 62, 19;
  --warning: #f59300;
  --warning-rgb: 245, 147, 0;
  --success: #0cce8d;
  --success-rgb: 12, 206, 141;
  // trading
  --buy: #0cce8d;
  --buy-rgb: 12, 206, 141;
  --sell: #ff7d5e;
  --sell-rgb: 255, 125, 94;
}

@color-outcome-list: var(--theme-outcome-0) var(--theme-outcome-1) var(--theme-outcome-2) var(--theme-outcome-3)
  var(--theme-outcome-4) var(--theme-outcome-5) var(--theme-outcome-6) var(--theme-outcome-7);

.generate-trading-indexed-color-outcome-classes(@rules) {
  each(@color-outcome-list, {
    @newIndex: @index;
    @rules();
  });
}
