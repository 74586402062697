@import (reference) "~assets/styles/shared";

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Spinner {
  animation: rotate 1s linear infinite;
  background: transparent;
  border-radius: 50%;
  height: @size-15;
  width: @size-15;
  position: relative;
  margin: 0;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }

  &:before {
    border-radius: 50%;
    background: linear-gradient(0deg, transparent 50%, rgba(var(--primary-text-rgb), 0.25) 100%) 0% 0%,
      linear-gradient(
          90deg,
          rgba(var(--primary-text-rgb), 0.25) 0%,
          rgba(var(--primary-text-rgb), 0.4) 100%
        )
        100% 0%,
      linear-gradient(
          180deg,
          rgba(var(--primary-text-rgb), 0.4) 0%,
          rgba(var(--primary-text-rgb), 0.7) 100%
        )
        100% 100%,
      linear-gradient(270deg, rgba(var(--primary-text-rgb), 0.7) 0%, var(--primary-text) 100%) 0% 100%;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:after {
    background-color: var(--secondary-bg);
    border-radius: 50%;
    top: @size-1;
    bottom: @size-1;
    left: @size-1;
    right: @size-1;
  }
}
